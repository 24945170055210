
// Libraries
import * as React from 'react'
import _ from "lodash"

// Services
import VenueService from '../../../services/venue'

// Components
import PrivateRoute from '../../../components/privateRoute'
import LayoutAdmin from '../../../components/layoutAdmin'
import Seo from '../../../components/seo'
import AdminVenuesTableHeader from "../../../components/admin/venues/adminVenuesTableHeader"
import AdminVenuesTable from "../../../components/admin/venues/adminVenuesTable"
import Pagination from "../../../components/pagination"
import AdminVenueView from "../../../components/admin/venues/adminVenueView"
import AdminVenueEdit from "../../../components/admin/venues/adminVenueEdit"
import AdminVenueConfirmDelete from "../../../components/admin/venues/adminVenueConfirmDelete"
import {faMapMarkerAlt} from "@fortawesome/pro-solid-svg-icons"
import Button from "../../../components/button"

// Context
import NotificationContext from '../../../contexts/notification'

class AdminVenuesIndexPage extends React.Component {
	state = {
		activeFilters: {
			'filter': {}
		},
		venues: [],
		pagination: [],
		emptyMessage: "Retrieving venue data ...",
		selectedVenue: {},
		modalViewShow: false,
		modalEditShow: false,
		modalConfirmDeleteShow: false
	}

	static contextType = NotificationContext

	render() {
		const {activeFilters, venues, pagination, emptyMessage, selectedVenue,
			modalViewShow, modalEditShow, modalConfirmDeleteShow} = this.state

		return <PrivateRoute admin>
			<LayoutAdmin className="page--admin-table nav-blue-half">
				<Seo title="Venues Management" />
				<div className="admin-table__wrap admin-table__wrap--venues-index">
					<AdminVenuesTableHeader
						titleText="Venues Management" titleIcon={faMapMarkerAlt}
						searchValue={activeFilters.search} searchHandler={_.debounce(this.handleSearchChange, 500)} />

					<div className="buttons-wrap">
						<Button to="/admin/venues/new" colorEndeavour>New Venue</Button>
					</div>

					<Pagination pagination={pagination} handlePaginationClick={this.handlePaginationClick} />

					<AdminVenuesTable
						venues={venues}
						getVenues={this.getVenues}
						emptyMessage={emptyMessage}
						handleOpenView={this.handleOpenView} />

					{modalViewShow && <AdminVenueView
						venue={selectedVenue}
						handleClose={() => this.setState({modalViewShow: false})}
						handleOpenEdit={this.handleOpenEdit}
						handleConfirmDelete={this.handleConfirmDelete} />}

					{modalEditShow && <AdminVenueEdit
						venue={selectedVenue}
						handleClose={() => this.setState({
							modalEditShow: false,
							modalViewShow: true
						})}
						handleEditComplete={this.handleEditComplete} />}

					{modalConfirmDeleteShow && <AdminVenueConfirmDelete
						venue={selectedVenue}
						handleClose={() => this.setState({modalConfirmDeleteShow: false})}
						handleConfirm={this.handleDelete} />}
				</div>
			</LayoutAdmin>
		</PrivateRoute>
	}

	componentDidMount() {
		const {activeFilters} = this.state
		const {addNotification} = this.context

		Promise.all([
			this.getVenues(activeFilters, false)
				.catch(() => addNotification('There was an error fetching the venues', 'error'))
		]).then(() => {
			this.setState({
				emptyMessage: "No venues found, try adjusting your filters"
			})
		})
	}

	getVenues = (queryParams = {}) => {
		const venueService = new VenueService()

		queryParams = {...this.state.activeFilters, ...queryParams}

		queryParams['paginate'] = 1
		return venueService.list(queryParams).then( (response) => {
			this.setState({
				venues: response.data.data,
				pagination: response.data,
				activeFilters: queryParams
			})
		}).catch(err => console.error(err))
	}

	handleSearchChange = (searchValue) => {
		const {activeFilters} = this.state

		const {addNotification} = this.context

		if (searchValue) {
			activeFilters['search'] = searchValue
		} else if (activeFilters['search']) {
			delete activeFilters['search']
		}

		if (activeFilters['page']) {
			delete activeFilters['page']
		}

		this.setState({activeFilters}, () => {
			this.getVenues(activeFilters)
				.catch(() => addNotification('There was an error filtering the venues', 'error'))
		})
	}

	handlePaginationClick = (page) => {
		const {activeFilters} = this.state
		const {addNotification} = this.context

		activeFilters['page'] = page

		this.setState({activeFilters}, () => {
			this.getVenues(activeFilters)
				.catch(() => addNotification('There was an error filtering the venues.', 'error'))
		})
	}

	handleOpenView = (venue_id) => {
		const venueService = new VenueService()
		return venueService.get(venue_id)
			.then( (response) => {
				this.updateStateSelectedVenue(response.data)
				this.setState({
					modalViewShow: true
				})
			})
			.catch(err => console.error(err))
	}

	handleOpenEdit = (venue_id) => {
		const venueService = new VenueService()
		return venueService.get(venue_id)
			.then( (response) => {
				this.updateStateSelectedVenue(response.data)
				this.setState({
					modalEditShow: true
				})
			})
			.catch(err => console.error(err))
	}

	handleEditComplete = (venue) => {
		this.updateStateSelectedVenue(venue)
		this.updateStateVenues(venue)
		this.setState({
			modalEditShow: false,
			modalViewShow: true
		})
	}

	handleConfirmDelete = (venue_id) => {
		const venueService = new VenueService()
		return venueService.get(venue_id)
			.then( (response) => {
				this.updateStateSelectedVenue(response.data)
				this.setState({
					modalConfirmDeleteShow: true
				})
			})
			.catch(err => console.error(err))
	}

	handleDelete = (venue_id) => {
		const venueService = new VenueService()

		const {addNotification} = this.context

		return venueService.delete(venue_id)
			.then( (response) => {
				if (response.data?.deleted) {
					addNotification('Venue deleted', 'success')
					this.updateStateVenues({id: venue_id}, true)
					this.setState({
						modalViewShow: false,
						modalConfirmDeleteShow: false
					})
				} else {
					addNotification('Unable to delete venue', 'error')
				}
			})
			.catch(err => console.error(err))
	}

	updateStateSelectedVenue = (venue) => {
		this.setState(prevState => ({
			selectedVenue: {...prevState.selectedVenue, ...venue}
		}))
	}

	updateStateVenues = (venue, remove = false) => {
		const {venues} = this.state

		// update events state
		venues.forEach((existingVenue, key) => {
			if (existingVenue.id === venue.id) {
				if (remove) {
					venues.splice(key, 1)
				}
				else {
					venues[key] = {...existingVenue, ...venue}
				}
			}
		})

		this.setState({venues})
	}
}

export default AdminVenuesIndexPage
