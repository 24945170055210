
// Libraries
import * as React from 'react'
import Classnames from 'classnames'

// Components
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faArrowRight} from '@fortawesome/pro-regular-svg-icons'

class AdminVenuesTableRow extends React.Component {
	render() {
		const {venue, handleOpenView} = this.props
		let classNames = Classnames([
			'admin-table__row',
		])

		return <tr className={classNames}>
			<td className="admin-table__cell--name">{venue.name}</td>
			<td className="admin-table__cell--venue">{venue.addresses.length ? venue.addresses[0].city : '-'}</td>
			<td className="admin-table__cell--venue">{venue.addresses.length ? venue.addresses[0].postal_code : '-'}</td>
			<td className="admin-table__cell--actions">
				<button onClick={() => handleOpenView(venue.id)}>
					<FontAwesomeIcon icon={faArrowRight} title="Edit icon" /><span className="sr-only"> View/Edit</span>
				</button>
			</td>
		</tr>
	}
}

export default AdminVenuesTableRow
