
// Libraries
import * as React from 'react'
import moment from "moment"

// Services
import ApiService from "../../../services/api"

// Components
import Modal from "../../modal"
import AdminVenueViewRow from "./adminVenueViewRow"
import InputFactory from "../../inputFactory"
import Button from "../../button"

// Context
import NotificationContext from '../../../contexts/notification'

class AdminVenueEdit extends React.Component {
	state = {
		inputValues: {
			address: {
				is_primary: 1,
				is_billing: 1,
				is_shipping: 1,
				country_code: 'GB'
			}
		},
		inputErrors: {
			address: {},
		},
		isSubmitting: false
	}

	static contextType = NotificationContext

	render() {
		const {venue, handleClose} = this.props
		const {inputValues, inputErrors, isSubmitting} = this.state
		const addressErrors = inputErrors ? {
			street: inputErrors['address.street'],
			street_2: inputErrors['address.street_2'],
			state: inputErrors['address.state'],
			postal_code: inputErrors['address.postal_code'],
			city: inputErrors['address.city'],
			region: inputErrors['address.region']
		} : {}

		return <Modal handleClose={handleClose} modalRight adminForm>
			<div className="admin-form admin-form--event">
				<div className="admin-form__header">
					<div className="column">
						<h3>{venue.name}</h3>
						<p>Added: {moment(venue.created).format('DD MMM YYYY')}</p>
					</div>
					<div className="column">
					</div>
				</div>
				<div className="admin-form__form">
					<form onSubmit={this.handleSubmit} encType="multipart/form-data">
						<div className="admin-form__field-group">
							<AdminVenueViewRow
								label="Name"
								value={<InputFactory
									type="text"
									name="name"
									value={inputValues?.name}
									error={inputErrors?.name}
									onChange={this.handleInputChange}
								/>} />
							<AdminVenueViewRow
								label="Address"
								value={<InputFactory
									type="address"
									name="address"
									value={inputValues?.address}
									error={addressErrors}
									onChange={this.handleInputChange}/>}
							/>
						</div>
						<div className="admin-form__field-group">
							<AdminVenueViewRow
								label="Website Link"
								value={<InputFactory
									type="text"
									name="website_url"
									value={inputValues?.website_url}
									error={inputErrors?.website_url}
									onChange={this.handleInputChange}
								/>} />
							<AdminVenueViewRow
								label="Directions Link"
								value={<InputFactory
									type="text"
									name="directions_url"
									value={inputValues?.directions_url}
									error={inputErrors?.directions_url}
									onChange={this.handleInputChange}
								/>} />
							<AdminVenueViewRow
								label="Customer Service Email"
								value={<InputFactory
									type="email"
									name="customer_service_email"
									value={inputValues?.customer_service_email}
									error={inputErrors?.customer_service_email}
									onChange={this.handleInputChange}
								/>} />
							<AdminVenueViewRow
								label="Customer Service Link"
								value={<InputFactory
									type="text"
									name="customer_service_url"
									value={inputValues?.customer_service_url}
									error={inputErrors?.customer_service_url}
									onChange={this.handleInputChange}
								/>} />
							<AdminVenueViewRow
								label="Security Information Link"
								value={<InputFactory
									type="text"
									name="security_information_url"
									value={inputValues?.security_information_url}
									error={inputErrors?.security_information_url}
									onChange={this.handleInputChange}
								/>} />

							<AdminVenueViewRow
								label="Default Door Times"
								value={<InputFactory
									type="time"
									name="default_door_times_time"
									value={inputValues?.default_door_times_time}
									error={inputErrors?.default_door_times_time}
									onChange={this.handleInputChange}
								/>} />
							<AdminVenueViewRow
								label="Default Starts Time"
								value={<InputFactory
									type="time"
									name="default_starts_time_time"
									value={inputValues?.default_starts_time_time}
									error={inputErrors?.default_starts_time_time}
									onChange={this.handleInputChange}
								/>} />
							<AdminVenueViewRow
								label="Default Max Tickets"
								value={<InputFactory
									type="number"
									step="1"
									min="0"
									name="default_max_tickets_per_transaction"
									value={inputValues?.default_max_tickets_per_transaction}
									error={inputErrors?.default_max_tickets_per_transaction}
									onChange={this.handleInputChange}
								/>} />
						</div>

						<div className="admin-form__submit-wrap">
							<Button type="button" onClick={handleClose} colorEndeavour hollow>Discard Changes</Button>
							<Button type="submit" isLoading={isSubmitting} colorEndeavour>Save Changes</Button>
						</div>
					</form>
				</div>
			</div>
		</Modal>
	}

	async componentDidMount() {
		const {venue} = this.props

		const {inputValues} = this.state
		const default_door_times = moment(venue.default_door_times)
		const default_starts_time = moment(venue.default_starts_time)

		inputValues.name = venue.name

		if (venue.addresses.length) {
			inputValues.address.street = venue.addresses[0].street
			inputValues.address.street_2 = venue.addresses[0].street_2
			inputValues.address.city = venue.addresses[0].city
			inputValues.address.state = venue.addresses[0].state
			inputValues.address.postal_code = venue.addresses[0].postal_code
			inputValues.address.region = venue.addresses[0].region
		}

		inputValues.website_url = venue.website_url
		inputValues.directions_url = venue.directions_url
		inputValues.security_information_url = venue.security_information_url
		inputValues.customer_service_email = venue.customer_service_email
		inputValues.customer_service_url = venue.customer_service_url
		inputValues.default_door_times_time = default_door_times.format('HH:mm')
		inputValues.default_starts_time_time = default_starts_time.format('HH:mm')
		inputValues.default_max_tickets_per_transaction = venue.default_max_tickets_per_transaction

		this.setState({inputValues})
	}

	handleInputChange = (value, name, scope) => {
		// Get current form data from state
		const {inputValues} = this.state

		if (value !== undefined) {
			// Update field value
			if (scope) {
				inputValues[scope][name] = value
			}
			else {
				inputValues[name] = value
			}
		} else {
			// Remove field value
			if (scope) {
				delete inputValues[scope][name]
			}
			else {
				delete inputValues[name]
			}
		}

		// Update state of file input values
		this.setState({ inputValues })
	}

	handleSubmit = async (submitEvent) => {
		submitEvent.preventDefault()
		this.setState({
			isSubmitting: true
		})

		const {venue, handleEditComplete} = this.props
		const {inputValues} = this.state
		const {addNotification} = this.context

		// prepare venue data
		inputValues['description'] = 'Description'
		const venueData = JSON.stringify(inputValues)

		// submit venue data
		const apiService = new ApiService()
		await apiService.put(`venues/${venue.id}`, {body: venueData})
			.then(async (venueResponse) => {
				if (venueResponse.success) {
					addNotification('Venue Updated', 'success')
					handleEditComplete(venueResponse.data)
				}
				else {
					this.setState({
						inputErrors: venueResponse.errors,
						isSubmitting: false
					})
				}
			})
			.catch(err => console.error(err))
	}
}

export default AdminVenueEdit
