
// Libraries
import * as React from 'react'

// Components
import Modal from "../../modal"
import Button from "../../button";

class AdminVenueConfirmDelete extends React.Component {
	state = {
		isConfirming: false
	}

	render() {
		const {handleClose, isConfirming} = this.props

		return <Modal handleClose={handleClose} modalConfirm>
			<div className="modal-confirm">
				<h2>Delete Venue?</h2>
				<p>It will no longer possible to select this venue from the list when creating a new event.
					Existing events won't be affected.</p>
				<ul className="button-wrap">
					<li><Button onClick={handleClose} hollow colorEndeavour>Back</Button></li>
					<li><Button onClick={this.handleConfirm} isLoading={isConfirming} colorPomegranate>Delete Venue</Button></li>
				</ul>
			</div>
		</Modal>
	}

	handleConfirm = () => {
		const {venue, handleConfirm} = this.props
		this.setState({
			isConfirming: true
		})
		handleConfirm(venue.id)
	}
}

export default AdminVenueConfirmDelete
