
// Libraries
import * as React from 'react'

// Components
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import InputFactory from "../../inputFactory"
import {faSearch} from "@fortawesome/pro-regular-svg-icons";

class AdminVenuesTableHeader extends React.Component {

	render() {
		const {titleIcon, titleText, infoText, searchValue, searchHandler} = this.props

		return <div className="admin-table__header">
			<div className="column column--title">
				<h1><FontAwesomeIcon icon={titleIcon} /> {titleText}</h1>
				{(infoText && infoText !== '') && <p>{infoText}</p>}
			</div>
			<div className="column column--search">
				<form>
					<FontAwesomeIcon icon={faSearch} className="search-icon" />
					<InputFactory
						type="search"
						name="search"
						placeholder="Search"
						value={searchValue}
						onChange={searchHandler}
						onKeyDown={this.handleOnKeyDown}
					/>
				</form>
			</div>
		</div>
	}

	handleOnKeyDown = (keyDownEvent) => {
		if(keyDownEvent.keyCode === 13) {
			keyDownEvent.preventDefault();
			return false;
		}
	}
}

export default AdminVenuesTableHeader
