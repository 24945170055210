
// Libraries
import * as React from 'react'
import Classnames from "classnames"

// Components
import AdminVenuesTableRow from "./adminVenuesTableRow"
import TableSort from '../tableSort'

class AdminVenuesTable extends React.Component {
	render() {
		const {venues, emptyMessage, handleOpenView, updateStateVenues, getVenues} = this.props

		let classNames = Classnames([
			'admin-table admin-table--venues wrap--scrollx',
		])

		return (
			<div className={classNames}>
				<table className="admin-table__table">
					<thead>
						<tr>
							<th>Name of Venue <TableSort fieldName="name" refreshData={getVenues}/></th>
							<th>City <TableSort fieldName="addresses.city" refreshData={getVenues}/></th>
							<th>Postcode <TableSort fieldName="addresses.postal_code" refreshData={getVenues}/></th>
							<th className="actions"><span className="sr-only">Actions</span></th>
						</tr>
					</thead>
					<tbody>
						{venues?.length
							? venues.map((venue) => <AdminVenuesTableRow
								key={venue.id}
								venue={venue}
								handleOpenView={handleOpenView}
								updateStateVenues={updateStateVenues} />)
							: <tr><td colSpan={100} align="center">{emptyMessage}</td></tr>}
					</tbody>
				</table>
			</div>
		)
	}
}

export default AdminVenuesTable
