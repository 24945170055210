
// Services
import ApiService from './api'

// Initialise new instance of the API service
const apiService = new ApiService()

export default class VenueService {
	async list(queryParams = {}) {
		return await apiService.get(`venues`, {}, queryParams)
	}

	async get(id = null) {
		return await apiService.get(`venues/${id}`)
	}

	async post(formData = {}) {
		return await apiService.post('venues', {body: formData})
	}

	async put(id, formData = {}) {
		return await apiService.put(`venues/${id}`, {body: formData})
	}

	async delete(id = null) {
		return await apiService.delete(`venues/${id}`)
	}
}
