
// Libraries
import * as React from 'react'
import moment from "moment"

// Components
import Modal from "../../modal"
import AdminVenueViewRow from "./adminVenueViewRow"
import Button from "../../button"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {faTrashAlt} from "@fortawesome/pro-solid-svg-icons"

class AdminVenueView extends React.Component {
	render() {
		const {venue, handleClose, handleOpenEdit, handleConfirmDelete} = this.props

		return <Modal handleClose={handleClose} modalRight>
			<div className="admin-view admin-view--venue">
				<div className="admin-view__header">
					<div className="column">
						<h3>{venue.name}</h3>
						<p>Added: {moment(venue.created).format('DD MMM YYYY')}</p>
					</div>
					<div className="column">
						<ul className="admin-view__header-nav">
							<li><Button onClick={() => handleOpenEdit(venue.id)} colorEndeavour>
								Edit Venue
							</Button></li>
							<li><Button to={`/admin/events/new?venueId=${venue.id}`} hollow colorEndeavour>
								New Event
							</Button></li>
							<li><Button onClick={() => handleConfirmDelete(venue.id)} iconOnly danger>
								<FontAwesomeIcon icon={faTrashAlt} title="Delete icon" />
								<span className="sr-only"> Delete Venue</span>
							</Button></li>
						</ul>
					</div>
				</div>
				<div className="admin-view__view">
					<div className="admin-view__view-group">
						<AdminVenueViewRow label="Name" value={venue.name} />
						<AdminVenueViewRow label="Address Line 1" value={venue.addresses.length ? venue.addresses[0].street : '-'} />
						{(venue.addresses.length > 0 && venue.addresses[0].street_2) &&
							<AdminVenueViewRow label="Address Line 2" value={venue.addresses[0].street_2} />}
						<AdminVenueViewRow label="City" value={venue.addresses.length ? venue.addresses[0].city : '-'} />
						<AdminVenueViewRow label="County" value={venue.addresses.length ? venue.addresses[0].state : '-'} />
						<AdminVenueViewRow label="Postcode" value={venue.addresses.length ? venue.addresses[0].postal_code : '-'} />
						<AdminVenueViewRow label="Region" value={venue.addresses.length ? venue.addresses[0].region : '-'} />
					</div>
					<div className="admin-view__view-group">
						<AdminVenueViewRow label="Website" value={venue.website_url} />
						<AdminVenueViewRow label="How to get there" value={venue.directions_url} />
						<AdminVenueViewRow label="Customer Service Email" value={venue.customer_service_email} />
						<AdminVenueViewRow label="Customer Service Link" value={venue.customer_service_url} />
						<AdminVenueViewRow label="Security Information" value={venue.security_information_url} />
					</div>
					<div className="admin-view__view-group">
						<AdminVenueViewRow label="Default Door Times" value={venue.default_door_times ? moment(venue.default_door_times).format("HH:mm") : "-"} />
						<AdminVenueViewRow label="Default Start Time" value={venue.default_starts_time ? moment(venue.default_starts_time).format("HH:mm") : "-"} />
						<AdminVenueViewRow label="Default Max Tickets" value={venue.default_max_tickets_per_transaction ? venue.default_max_tickets_per_transaction: "-"} />
					</div>
				</div>
			</div>
		</Modal>
	}
}

export default AdminVenueView
